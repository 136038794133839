
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getGroups } from "@/core/services/api/groups";
import localstorage from "@/core/services/LocalstorageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { searchItems } from "@/core/utils/tableSearch";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import AddGroup from "./components/AddGroup.vue";
import UpdateSpaceGroup from "./components/UpdateSpaceGroup.vue";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import { Modal } from "bootstrap";
import { removeSpaceGroup } from "@/core/services/api/spaceGroup";

export default defineComponent({
  components: {
    Datatable,
    AddGroup,
    UpdateSpaceGroup,
  },
  emits: ["onAddGroup", "onUpdateSpaceGroup"],
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const currentGroup: any = reactive({
      name: "",
      id: "",
    });
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const search = ref<string>("");
    const isLoadind = ref(false);
    const tableHeader: any = reactive([
      { key: "name", name: "名稱", sortable: true },
      { key: "id", name: "代碼", sortable: true },
      { key: "createTime", name: "創建日期", sortable: true },
      { key: "operate", name: "操作" },
    ]);
    let addGroupModal;
    let updateSpaceGroupModal;
    const handleGetGroups = async () => {
      const groupsGuid: any = {
        groups: localstorage.getCurrentSpaces()?.groups,
      };
      if (groupsGuid.groups.length > 0) {
        return await getGroups(groupsGuid);
      }
      return [];
    };

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const formatDate = (date) => {
      return getLocalTimeString(date);
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let groups = await handleGetGroups();
      tableData.splice(0, tableData.length, ...groups);
      initTableData.splice(0, initTableData.length, ...groups);
      isLoadind.value = false;
      console.log("tableData", tableData);
    };

    const onAddGroup = async () => {
      addGroupModal.hide();
      await setTableData();
    };

    const setCurrentGroup = (item) => {
      Object.assign(currentGroup, item);
    };

    const onUpdateSpaceGroup = async () => {
      updateSpaceGroupModal.hide();
      await setTableData();
    };

    const handleRemoveSpaceGroup = async () => {
      await Swal.fire({
        title: "您確定要刪除單位嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let request = {
            space: currentSpaceGuid,
            group: currentGroup.guid,
          };
          await removeSpaceGroup(request);
          await setTableData();
          Swal.fire("部門已刪除!", "", "success");
        }
      });
    };

    onMounted(() => {
      addGroupModal = new Modal(document.getElementById("addGroup-modal"));
      updateSpaceGroupModal = new Modal(
        document.getElementById("updateGroup-modal")
      );
    });

    const init = async () => {
      await setTableData();
    };
    init();

    return {
      tableData,
      tableHeader,
      initTableData,
      tableSearch,
      isLoadind,
      search,
      formatDate,
      onAddGroup,
      setCurrentGroup,
      currentGroup,
      onUpdateSpaceGroup,
      handleRemoveSpaceGroup,
    };
  },
});
