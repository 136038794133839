<template>
  <div>
    <el-form
      @submit.prevent="submit()"
      :model="form"
      ref="formRef"
      class="form"
      :rules="rules"
    >
      <div>
        <label class="fs-6 fw-bold mb-2">
          <span class="required">名稱</span>
        </label>
        <el-form-item prop="name">
          <el-input v-model="form.name" placeholder="請輸入名稱"></el-input>
        </el-form-item>
        <label class="fs-6 fw-bold mb-2">
          <span class="required">代碼</span>
        </label>
        <el-form-item prop="id">
          <el-input v-model="form.id" placeholder="請輸入Email"></el-input>
        </el-form-item>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-success" type="submit">送出</button>
      </div>
    </el-form>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { updateSpaceGroup } from "@/core/services/api/spaceGroup";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  props: ["currentGroup"],
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const formRef = ref<null | HTMLFormElement>(null);
    const form = reactive({
      name: "",
      id: "",
    });
    const rules = reactive({
      name: [
        {
          required: true,
          message: "請填寫名稱",
          trigger: "blur",
        },
      ],
      id: [
        {
          required: true,
          message: "請填寫ID",
          trigger: "blur",
        },
      ],
    });

    const submit = async () => {
      await Swal.fire({
        title: "您確定要更新部門資料嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let request = {
            space: currentSpaceGuid,
            group: props.currentGroup.guid,
            id: form.id,
            name: form.name,
          };
          await updateSpaceGroup(request);
          await Swal.fire("資料已更新!", "", "success");
            emit("onUpdateSpaceGroup");
        }
      });
    };

    watch(
      props,
      () => {
        form.name = props.currentGroup.name;
        form.id = props.currentGroup.id;
      },
      {
        deep: true,
        immediate: true,
      }
    );

    return {
      formRef,
      form,
      submit,
      props,
      rules,
    };
  },
});
</script>

