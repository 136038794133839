import ApiService from "@/core/services/ApiService";

// 新增空間群組
export function addSpaceGroup(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`SpaceGroup/AddGroup`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 更新群組資料
export function updateSpaceGroup(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`SpaceGroup/UpdateGroupData`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 空間刪除群組
export function removeSpaceGroup(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`SpaceGroup/RemoveGroup`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}