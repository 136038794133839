<template>
  <div>
    <el-form
      @submit.prevent="submit()"
      :model="form"
      :rules="rules"
      ref="formRef"
      class="form"
    >
      <div>
        <label class="fs-6 fw-bold mb-2">
          <span class="required">名稱</span>
        </label>
        <el-form-item prop="name">
          <el-input v-model="form.name" placeholder="請輸入名稱"></el-input>
        </el-form-item>
        <label class="fs-6 fw-bold mb-2">
          <span class="required">代碼</span>
        </label>
        <el-form-item prop="id">
          <el-input v-model="form.id" placeholder="請輸入Email"></el-input>
        </el-form-item>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-success" type="submit">送出</button>
      </div>
    </el-form>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import localstorage from "@/core/services/LocalstorageService";
import { addSpaceGroup } from "@/core/services/api/spaceGroup";
import { getSpacesByManager } from "@/core/services/api/spaces";

export default defineComponent({
  props: ["groupOptions", "isGroup"],
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      name: [
        {
          required: true,
          message: "請填寫名稱",
          trigger: "blur",
        },
      ],
      id: [
        {
          required: true,
          message: "請填寫代碼",
          trigger: "blur",
        },
      ],
    });
    const form = reactive({
      name: "",
      id: "",
    });

    const reseatForm = () => {
      Object.assign(form, {
        name: "",
        id: "",
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增部門嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                space: currentSpaceGuid,
                id: form.id,
                name: form.name,
              };
              await addSpaceGroup(request);
              await getSpacesByManager().then((spaces) => {
                localstorage.saveSpaces(spaces);
                let currentSpaces: any = spaces.filter(
                  (o) => o.space.guid == currentSpaceGuid!
                );
                localstorage.saveCurrentSpaces(currentSpaces[0]);
                emit("onAddGroup");
                Swal.fire("新增成功!", "", "success");
              });
            }
          });
        }
      });
    };

    onMounted(() => {
      let addGroupModal: any = document.getElementById("addGroup-modal");
      addGroupModal.addEventListener("hidden.bs.modal", function () {
        reseatForm();
        formRef.value!.resetFields();
      });
    });

    return {
      rules,
      formRef,
      form,
      submit,
      props,
    };
  },
});
</script>

<style scoped>
</style>