
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { updateSpaceGroup } from "@/core/services/api/spaceGroup";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  props: ["currentGroup"],
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const formRef = ref<null | HTMLFormElement>(null);
    const form = reactive({
      name: "",
      id: "",
    });
    const rules = reactive({
      name: [
        {
          required: true,
          message: "請填寫名稱",
          trigger: "blur",
        },
      ],
      id: [
        {
          required: true,
          message: "請填寫ID",
          trigger: "blur",
        },
      ],
    });

    const submit = async () => {
      await Swal.fire({
        title: "您確定要更新部門資料嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let request = {
            space: currentSpaceGuid,
            group: props.currentGroup.guid,
            id: form.id,
            name: form.name,
          };
          await updateSpaceGroup(request);
          await Swal.fire("資料已更新!", "", "success");
            emit("onUpdateSpaceGroup");
        }
      });
    };

    watch(
      props,
      () => {
        form.name = props.currentGroup.name;
        form.id = props.currentGroup.id;
      },
      {
        deep: true,
        immediate: true,
      }
    );

    return {
      formRef,
      form,
      submit,
      props,
      rules,
    };
  },
});
