<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <!--begin::Card title-->
      <div class="row col d-flex">
        <div
          class="
            col-lg-3 col-12
            d-flex
            align-items-center
            position-relative
            my-1
          "
        >
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="tableSearch()"
            class="form-control form-control-solid ps-15"
            placeholder="搜尋"
          />
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          <button
            class="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#addGroup-modal"
          >
            新增
          </button>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div v-if="isLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-name="{ row: tableData }">
          {{ tableData.name }}
        </template>
        <template v-slot:cell-id="{ row: tableData }">
          {{ tableData.id }}
        </template>
        <template v-slot:cell-createTime="{ row: tableData }">
          {{ formatDate(tableData.createTime) }}
        </template>

        <template v-slot:cell-operate="{ row: tableData }">
          <el-dropdown>
            <span
              @mouseover="setCurrentGroup(tableData)"
              class="el-dropdown-link text-primary"
            >
              功能選單<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  data-bs-toggle="modal"
                  data-bs-target="#updateGroup-modal"
                  >修改</el-dropdown-item
                >
                <el-dropdown-item @click="handleRemoveSpaceGroup()"
                  >刪除單位</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Datatable>
    </div>
  </div>
  <!-- 新增群組modal -->
  <div
    class="modal fade"
    id="addGroup-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addGroup-exampleModalLabel">新增單位</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AddGroup @onAddGroup="onAddGroup()"></AddGroup>
        </div>
      </div>
    </div>
  </div>
  <!-- 修改群組資料modal -->
  <div
    class="modal fade"
    id="updateGroup-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="updateGroup-exampleModalLabel">
            修改單位資料
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <UpdateSpaceGroup
            :currentGroup="currentGroup"
            @onUpdateSpaceGroup="onUpdateSpaceGroup()"
          ></UpdateSpaceGroup>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getGroups } from "@/core/services/api/groups";
import localstorage from "@/core/services/LocalstorageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { searchItems } from "@/core/utils/tableSearch";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import AddGroup from "./components/AddGroup.vue";
import UpdateSpaceGroup from "./components/UpdateSpaceGroup.vue";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import { Modal } from "bootstrap";
import { removeSpaceGroup } from "@/core/services/api/spaceGroup";

export default defineComponent({
  components: {
    Datatable,
    AddGroup,
    UpdateSpaceGroup,
  },
  emits: ["onAddGroup", "onUpdateSpaceGroup"],
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const currentGroup: any = reactive({
      name: "",
      id: "",
    });
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const search = ref<string>("");
    const isLoadind = ref(false);
    const tableHeader: any = reactive([
      { key: "name", name: "名稱", sortable: true },
      { key: "id", name: "代碼", sortable: true },
      { key: "createTime", name: "創建日期", sortable: true },
      { key: "operate", name: "操作" },
    ]);
    let addGroupModal;
    let updateSpaceGroupModal;
    const handleGetGroups = async () => {
      const groupsGuid: any = {
        groups: localstorage.getCurrentSpaces()?.groups,
      };
      if (groupsGuid.groups.length > 0) {
        return await getGroups(groupsGuid);
      }
      return [];
    };

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const formatDate = (date) => {
      return getLocalTimeString(date);
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let groups = await handleGetGroups();
      tableData.splice(0, tableData.length, ...groups);
      initTableData.splice(0, initTableData.length, ...groups);
      isLoadind.value = false;
      console.log("tableData", tableData);
    };

    const onAddGroup = async () => {
      addGroupModal.hide();
      await setTableData();
    };

    const setCurrentGroup = (item) => {
      Object.assign(currentGroup, item);
    };

    const onUpdateSpaceGroup = async () => {
      updateSpaceGroupModal.hide();
      await setTableData();
    };

    const handleRemoveSpaceGroup = async () => {
      await Swal.fire({
        title: "您確定要刪除單位嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let request = {
            space: currentSpaceGuid,
            group: currentGroup.guid,
          };
          await removeSpaceGroup(request);
          await setTableData();
          Swal.fire("部門已刪除!", "", "success");
        }
      });
    };

    onMounted(() => {
      addGroupModal = new Modal(document.getElementById("addGroup-modal"));
      updateSpaceGroupModal = new Modal(
        document.getElementById("updateGroup-modal")
      );
    });

    const init = async () => {
      await setTableData();
    };
    init();

    return {
      tableData,
      tableHeader,
      initTableData,
      tableSearch,
      isLoadind,
      search,
      formatDate,
      onAddGroup,
      setCurrentGroup,
      currentGroup,
      onUpdateSpaceGroup,
      handleRemoveSpaceGroup,
    };
  },
});
</script>

<style>
</style>